import Authentication from './Backend/Authentication';
import Files from './Backend/Files';
import Account from './Backend/Account';

class Backend {
    static async performLogin(username, password) {
        return await Authentication.performLogin(username, password);
    }

    static async validateToken(token) {
        return await Authentication.validateToken(token);
    }

    static async getFiles(token) {
        return await Files.getFiles(token);
    }

    static async searchFiles(token) {
        return await Files.searchFiles(token);
    }

    static async deleteFile(token, fileID) {
        return await Files.deleteFile(token, fileID);
    }

    static async createAccount() {
        return await Account.createAccount();
    }

    static async changePassword(token) {
        return await Account.changePassword(token);
    }
}

export default Backend;