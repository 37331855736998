import axios from 'axios';

const serverURL = "https://xthou.com";

class Files {
    static async getFiles(token) {
        let files = [];
        try {
            const url = serverURL + "/f";
            const authorizationToken = "Bearer " + token;

            let sortOrder;
            if(window.localStorage.getItem('sortOrder')) {
                const localSortOrder = JSON.parse(window.localStorage.getItem('sortOrder'));
                Object.keys(localSortOrder).forEach(item => {if(localSortOrder[item]) sortOrder = item.toLowerCase()});
            }
            const filesRequest = await axios({
                method: "POST",
                url: url,
                headers: { 'Content-Type': 'application/json', 'authorization': authorizationToken},
                data: {'sortOrder': sortOrder}
    
            });
            files = filesRequest.data;
        }catch (e) {
            files = [];
        }
        return files;
    }

    static async searchFiles(token) {
        const searchInput = document.getElementById("SearchInput");
        if(searchInput && searchInput.value) {
            let files = [];
            const url = serverURL + "/f/s/" + searchInput.value;
            const authorizationToken = "Bearer " + token;
            try {
                const requestResponse = await axios({
                    method: "GET",
                    url: url,
                    headers: { 'Content-Type': 'application/json', 'authorization': authorizationToken}
                });
                files = requestResponse.data;
                
            }catch (e) {
                files = [];
            }
            return files;
        }
    }

    static async deleteFile(token, fileID) {
        const url = serverURL + "/api/files/delete/" + fileID;
        const authorizationToken = "Bearer " + token;
        let successful = false;
        try {
            await axios({
                method: "delete",
                url: url,
                headers: {'Content-Type': 'application/json', 'authorization': authorizationToken}
            });
            successful = true;
        }catch (e) {
            successful = false;
        }
        return successful;
    }
}

export default Files;