import { ContentGroup } from '../xui';
import File from '../File';

function Tuple(files) {
    let fileOne, fileTwo, fileThree;
    if(files.files[0]) fileOne = 
        <File file={{
            fileName: files.files[0].fileName,
            fileSize: files.files[0].fileSize,
            fileType: files.files[0].fileType,
            timeOfUpload: files.files[0].timeOfUpload,
            uniqueID: files.files[0].uniqueID,
            uploaderID: files.files[0].uploaderID,
        }}
    />
    if(files.files[1]) fileTwo = 
        <File file={{
            fileName: files.files[1].fileName,
            fileSize: files.files[1].fileSize,
            fileType: files.files[1].fileType,
            timeOfUpload: files.files[1].timeOfUpload,
            uniqueID: files.files[1].uniqueID,
            uploaderID: files.files[1].uploaderID,
        }}
    />
    if(files.files[2]) fileThree = 
        <File file={{
            fileName: files.files[2].fileName,
            fileSize: files.files[2].fileSize,
            fileType: files.files[2].fileType,
            timeOfUpload: files.files[2].timeOfUpload,
            uniqueID: files.files[2].uniqueID,
            uploaderID: files.files[2].uploaderID,
        }}
    />
    return (
        <>
            <ContentGroup style={{overflow: 'auto'}}>
                {fileOne}
                {fileTwo}
                {fileThree}
            </ContentGroup>
        </>
    );
}

export default Tuple;