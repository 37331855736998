import { useState, useEffect } from 'react';
import {
    ContentGroup,
    ContentGroupItem,
    Spacer,
    Button,
    Icon,
    Field,
    Modal,
    Form,
    FormRow,
    Checkbox
} from './xui';

import Tuple from './Files/Tuple';

import Backend from '../Backend';

function iterateAndPushFilesToArray(files, step, iterations, arr) {
    if(step < files.length) {
        const lessThanValue = iterations + step;
        for(step; step < lessThanValue; step++) {
            arr.push(files[step]);
        }
    }
}

function Files(props) {
    const [step, setStep] = useState(0);
    const [refreshButtonLoading, setRefreshButtonLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [moreButtonLoading, setMoreButtonLoading] = useState(false);
    const [sortModalVisible, setSortModalVisible] = useState(false);
    let sortOrder;
    if(window.localStorage.getItem('sortOrder')) {
        sortOrder = JSON.parse(window.localStorage.getItem('sortOrder'));

    }else {
        sortOrder = {
            Alphabetical: false,
            Chronological: false,
            Size: false,
            None: true
        };
        window.localStorage.setItem('sortOrder', JSON.stringify(sortOrder));
    }
    const [sortModalOptions, setSortModalOptions] = useState(sortOrder);

    const toggleSortModal = () => setSortModalVisible(!sortModalVisible);
    const closeSortModal = () => setSortModalVisible(false);

    async function getUserFiles() {
        if(!refreshButtonLoading) {
            setRefreshButtonLoading(true);
            const tokenValid = await Backend.validateToken(window.localStorage.getItem('token'));
            if(tokenValid) {
                const requestFiles = await Backend.getFiles(window.localStorage.getItem('token'));
                window.sessionStorage.setItem('files', JSON.stringify(requestFiles));
                let initial = [];
                iterateAndPushFilesToArray(requestFiles, 0, 12, initial);
                setStep(12);
                setFiles(initial);
            }else {
                props.logout();
            }
            setRefreshButtonLoading(false); 
        }
    }

    async function loadFiles() {
        if(!moreButtonLoading) {
            setMoreButtonLoading(true);
            if(window.sessionStorage.getItem('files')) {
                const localFiles = JSON.parse(window.sessionStorage.getItem('files'));
                let modifiedFiles = files;
                iterateAndPushFilesToArray(localFiles, 0, 12, modifiedFiles);
                setStep((step + 12));
                setFiles(modifiedFiles);
    
            }else {
                await getUserFiles();
            }
            setMoreButtonLoading(false);
        }
    }

    useEffect(() => {
        loadFiles();
    }, []);

    function Feed() {
        let tuples = [];
        let finalIndex = 0;
        for(let i = 3; i <= files.length; i += 3) {
            let tupleFiles = [files[i - 3], files[i - 2], files[i - 1]];
            tuples.push(<Tuple files={tupleFiles} />);
            finalIndex = i;
        }
        const remainingFiles = files.length - finalIndex;
        if(remainingFiles === 2) {
            let tupleFiles = [files[finalIndex], files[finalIndex + 1]];
            tuples.push(<Tuple files={tupleFiles} />);

        }
        if(remainingFiles === 1) {
            let tupleFiles = [files[finalIndex]];
            tuples.push(<Tuple files={tupleFiles} />);
        }

        return tuples;
    }

    const feed = Feed();
    
    function loadMoreFiles() {
        console.log('called')
        const localFiles = JSON.parse(window.sessionStorage.getItem('files'));
        if(step < localFiles.length) {
            setMoreButtonLoading(true);
            let modifiedFiles = files;
            iterateAndPushFilesToArray(localFiles, step, 12, modifiedFiles);
            setStep((step + 12));
            setFiles(modifiedFiles);
            setMoreButtonLoading(false);
        }
    }

    async function searchFiles(event) {
        if(event.target.value && event.target.value.length >= 1 && event.target.value !== undefined) {
            const token = window.localStorage.getItem('token')
            const searchResults = await Backend.searchFiles(token);
            if(searchResults.length >= 1) {
                setFiles(searchResults);
            }
        }else {
            if(window.sessionStorage.getItem('files')) {
                const localFiles = JSON.parse(window.sessionStorage.getItem('files'));
                let modifiedFiles = [];
                iterateAndPushFilesToArray(localFiles, 0, 12, modifiedFiles);
                setStep(12);
                setFiles(modifiedFiles);
    
            }else {
                await getUserFiles();
            }
        }
    }

    function handleSort(toChange) {
        const sortOrder = JSON.parse(window.localStorage.getItem('sortOrder'));
        if(!sortOrder[toChange]) {
            let updatedSortOrder = sortOrder;
            Object.keys(updatedSortOrder).forEach(key => updatedSortOrder[key] = false);
            updatedSortOrder[toChange] = true;
            window.localStorage.setItem('sortOrder', JSON.stringify(updatedSortOrder));
            setSortModalOptions({...updatedSortOrder});
        }
    }

    return (
        <>
            <Modal visible={sortModalVisible} close={closeSortModal} form>
               <Form>
                   <FormRow label="Sort">
                        <Checkbox label="Alphabetical" checked={sortModalOptions.Alphabetical} onChange={() => handleSort("Alphabetical")} />
                   </FormRow>
                   <FormRow>
                        <Checkbox label="Chronological" checked={sortModalOptions.Chronological} onChange={() => handleSort("Chronological")} />
                   </FormRow>
                   <FormRow>
                        <Checkbox label="Size" checked={sortModalOptions.Size} onChange={() => handleSort("Size")} />
                   </FormRow>
                   <FormRow>
                        <Checkbox label="None" checked={sortModalOptions.None} onChange={() => handleSort("None")} />
                   </FormRow>
                   <Spacer />
                   <FormRow>
                       <Button color="primary" filled onClick={closeSortModal}>OK</Button>
                   </FormRow>
               </Form>
            </Modal>
            <ContentGroup>
                <ContentGroupItem grow={false}>
                    <Button 
                        iconLeft={<Icon icon="RefreshOutline" fill="white" height="24" width="24" paddingSize="l" />} 
                        color="primary"
                        filled
                        onClick={getUserFiles}
                        loading={refreshButtonLoading}
                    >Refresh</Button>
                </ContentGroupItem>
                <ContentGroupItem grow={false}>
                    <Button 
                        iconLeft={<Icon icon="FunnelOutline" fill="white" height="24" width="24" paddingSize="l" />} 
                        color="primary"
                        filled
                        onClick={toggleSortModal}
                    >Sort</Button>
                </ContentGroupItem>
                <ContentGroupItem alignItems="end">
                    <Field 
                        prepend={<Icon icon="SearchOutline" fill="white" height="24" width="24" paddingSize="l" />}
                        type="text" 
                        placeholder="Search"
                        id="SearchInput"
                        onChange={searchFiles}
                    />
                </ContentGroupItem>
            </ContentGroup>
            <Spacer size="xl" />
            <ContentGroup responsive={false} direction="column" style={{overflow: 'auto'}}>
                {
                    Object.keys(feed).map(key => feed[key])
                }
                <Spacer />
                <ContentGroupItem alignItems="center">
                    <div style={{width: '250px'}}>
                        <Button 
                            color="primary"
                            loading={moreButtonLoading}
                            onClick={loadMoreFiles}
                        >More</Button>
                    </div>
                </ContentGroupItem>
            </ContentGroup>
        </>  
    );
}

export default Files;