import { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from './components/Login';
import Core from './components/Core';
import CreateAccount from './components/CreateAccount';
import ChangePassword from './components/ChangePassword';

import Backend from './Backend';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  function logout() {
      window.localStorage.removeItem('token');
      window.sessionStorage.removeItem('files');
      setLoggedIn(false);
  }
  
  async function login() {
      const username = document.getElementById("UsernameInput").value;
      const password = document.getElementById("PasswordInput").value;
      if(username && password) {
          setLoading(true);
          const response = await Backend.performLogin(username, password);
          if(!response) {
              alert("Login failed");
              setLoggedIn(false);

          }else {
              setLoggedIn(true);
          }
          setLoading(false);
      }
  }

  useEffect(() => {
    async function checkToken() {
      if(window.localStorage.getItem('token') && !loggedIn) {
        const tokenValid = await Backend.validateToken(window.localStorage.getItem('token'));
        if(tokenValid) {
          setLoggedIn(true);

        }else {
          setLoggedIn(false);
          if(window.sessionStorage.getItem('files')) window.sessionStorage.removeItem('files');
          if(window.localStorage.getItem('token')) window.localStorage.removeItem('token');
        }
      }
    }
    checkToken();
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/ca">
          <CreateAccount />
        </Route>
        <Route exact path="/password">
          <ChangePassword />
        </Route>
        <Route path="/">
          {loggedIn ? <Core logout={logout} /> : <Login onClick={login} loading={loading} />}
        </Route>
      </Switch>
    </div>
  );
}

export default App;
