import './Login.css';
import {
    Page,
    Form,
    FormRow,
    Field,
    Icon,
    Button,
    Spacer
} from './xui';

function Login(props) {
    return (
        <Page>
            <div className="LoginWrapper">
                <div className="LoginFormWrapper">
                    <Form>
                        <FormRow label="Username">
                            <Field 
                                prepend={<Icon icon="PersonOutline" fill="white" height="24" width="24" paddingSize="l" />}
                                type="text"
                                placeholder="Username"
                                id="UsernameInput"
                            />
                        </FormRow>
                        <FormRow label="Password">
                            <Field 
                                prepend={<Icon icon="LockOutline" fill="white" height="24" width="24" paddingSize="l" />}
                                type="password"
                                placeholder="Password"
                                id="PasswordInput"
                                onKeyPress={(ev) => {
                                    if(ev.key === "Enter") {
                                      ev.preventDefault();
                                      props.onClick();
                                    }
                                }}
                            />
                        </FormRow>
                        <Spacer />
                        <FormRow button>
                            <Button 
                                color="primary" 
                                filled 
                                onSubmit={props.onSubmit} 
                                onClick={props.onClick} 
                                loading={props.loading}
                            >Login</Button>
                        </FormRow>
                    </Form>
                </div>        
            </div>
        </Page>
    );
}

export default Login;