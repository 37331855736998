import './Upload.css';
import { useState } from 'react';
import axios from 'axios';
import {
    Form,
    FormRow,
    FilePicker,
    Checkbox,
    Button,
    Field,
    Icon,
    ProgressBar,
} from './xui';

import Backend from '../Backend';

const serverURL = "https://xthou.com";
const maxFileSize = 21474836480;

function Upload(props) {
    const [files, setFiles] = useState([]);
    const [uploadAsCollection, setUploadAsCollection] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const clearFiles = () => {setUploadInProgress(false); setFiles([])};
    const filePickerChange = (e) => setFiles(e.target.files);
    const toggleUploadAsCollection = () => setUploadAsCollection(!uploadAsCollection);

    let uploadAsCollectionFormRow, collectionNameFormRow, progressBar;
    
    if(files.length > 1) uploadAsCollectionFormRow = (
        <FormRow>
            <Checkbox label="Upload as a collection" checked={uploadAsCollection} onChange={toggleUploadAsCollection} />
        </FormRow>
    );

    if(files.length > 1 && uploadAsCollection) collectionNameFormRow = (
        <FormRow label="Collection name">
            <Field 
                prepend={<Icon icon="FolderOutline" fill="white" height="24" width="24" paddingSize="l" />}
                type="text" 
                placeholder="Name" 
            />
        </FormRow>
    );

    if(uploadInProgress) progressBar = (
        <FormRow>
            <ProgressBar value={uploadProgress} max={100} size="xl" showProgressText />
        </FormRow>
    );

    /*
        </FormRow>
        {uploadAsCollectionFormRow}
        {collectionNameFormRow}
        <Spacer />
    */
    
    async function uploadFiles() {
        if(!uploadInProgress) {
            setUploadInProgress(true);
            const tokenValid = await Backend.validateToken(window.localStorage.getItem('token'));
            if(files.length > 0 && !uploadInProgress) {
                if(tokenValid) {
                    let totalFileSize = 0;
                    const formData = new FormData();
                    Object.keys(files).forEach(file => {
                        totalFileSize += files[file].size;
                        formData.append(files[file].name, files[file]);
                    });
                    if(totalFileSize <= maxFileSize) {
                        const url = serverURL + "/api/files/upload";
                        const authorizationToken = "Bearer " + window.localStorage.getItem('token');
                        try {
                            await axios({
                                method: "POST",
                                url: url,
                                data: formData,
                                headers: { 'Content-Type': 'multipart/form-data', 'authorization': authorizationToken },
                                onUploadProgress: progressEvent => {
                                    let progress = Math.round(progressEvent.loaded * 100) / progressEvent.total;
                                    setUploadProgress(progress.toFixed(1));
                                }
                            });
                            alert("Upload Successful!");
                            
                        }catch (e) {
                            console.log(e);
                            alert("Upload failed!");
                        }
                        setFiles([]);
                    }else {
                        alert("File(s) size too large!");
                    }
                }else {
                    props.logout();
                }
            }else {
                alert("No files selected");
            }
            setUploadInProgress(false);
        }
    }

    return (
        <>
            <div className="UploadWrapper">
                <div className="UploadFormWrapper">
                    <Form>
                        <FormRow label="Select file(s)">
                            <FilePicker onInput={filePickerChange} files={files} clearFiles={clearFiles} multiple expanded />
                        </FormRow>
                        {progressBar}
                        <FormRow button>
                            <Button color="primary" filled onClick={uploadFiles} loading={uploadInProgress}>Upload</Button>
                        </FormRow>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default Upload;