import axios from 'axios';

const serverURL = "https://xthou.com";

class Authentication {
    static async performLogin(username, password) {
        const url = serverURL + "/api/authentication/login";
        let response = false;
        try {
            const requestResponse = await axios.post(url, {
                username: username,
                password: password
            });
            window.localStorage.setItem('token', requestResponse.data.token);
            response = true;

        }catch(e) {
            response = false;
        }
        return response;
    }

    static async validateToken(token) {
        const url = serverURL + "/api/authentication/token";
        let authorizationToken = "Bearer " + token;
        let response = false;
        try {
            const requestResponse = await axios({
                method: "POST",
                url: url,
                headers: { 'Content-Type': 'multipart/form-data', 'authorization': authorizationToken },
                
            });
            if(requestResponse.status === 200) {
                response = true;
            }
        }catch (e) {
            response = false;
        }
        return response;
    }
}

export default Authentication;