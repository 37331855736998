import './Overlay.css';
import '../Theme.css';
import { useEffect } from 'react';

function Overlay(props) {
    useEffect(() => {
        if(props.visible) {
            document.body.style.overflow = "hidden";
            
        }else {
            document.body.style.overflow = "auto";
        }
    }, [props.visible]);

    function overlayClick(e) {
        e.stopPropagation();
        if(e.target.id !== props.exclusionaryID && e.target.id !== undefined && !props.noClickClose) props.close();
    }

    return (
        <div className={props.visible ? "Overlay" : "Overlay Closed"} onClick={overlayClick} {...props}>
            {props.children}
        </div>
    );
}

export default Overlay;