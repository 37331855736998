import './Core.css';
import { useState, useEffect,  } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import {
    Page,
    Spacer,
    ContentGroup,
    ContentGroupItem,
    Tabs,
    Tab,
  } from './xui';

import Upload from './Upload';
import Files from './Files';

function Core(props) {
    const [tabs, setTabs] = useState({"Upload": true, "Files": false});
    const history = useHistory();
    const location = useLocation();

    const toggleTab = (clicked, push = true) => {
      if(!tabs[clicked]) {
        let updatedTabs = tabs;
        Object.keys(updatedTabs).forEach(tab => updatedTabs[tab] = false);
        updatedTabs[clicked] = true;
        setTabs({...updatedTabs});
        if(push) history.push('/' + clicked.toLowerCase());
      }
    }
    
    useEffect(() => {
      const split = location.pathname.split('/');
      const path = split[split.length - 1];
      const pathUppercase = path.charAt(0).toUpperCase() + path.slice(1);
      if(path === "") {
        toggleTab('Upload', true);

      }else {
        toggleTab(pathUppercase, false);
        console.log(split[split.length - 1]);
      }
    }, []);

    return (
        <Page>
          <ContentGroup responsive={false}>
            <ContentGroupItem>
              <Tabs justifyContent="center">
                <Tab active={tabs.Upload} onClick={() => toggleTab("Upload")}>Upload</Tab>
                <Tab active={tabs.Files} onClick={() => toggleTab("Files")}>Files</Tab>
                <Tab active={true} onClick={props.logout} id="LogoutTab">Logout</Tab>
              </Tabs>
            </ContentGroupItem>
          </ContentGroup>
          <Spacer size="l" />
          <Switch>
            <Route exact path="/files">
              <Files logout={props.logout} />
            </Route>
            <Route exact path="/collections">
              
            </Route>
            <Route>
              <Upload logout={props.logout} />
            </Route>
          </Switch>
        </Page>
    );
}

export default Core;