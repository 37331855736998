import { useState } from 'react';
import {
    Page,
    Form,
    FormRow,
    Field,
    Icon,
    Spacer,
    Button,
} from './xui';

import Backend from '../Backend';

function CreateAccount() {
    const [loading, setLoading] = useState(false);

    async function createAccount() {
        setLoading(true);
        await Backend.createAccount();
        setLoading(false);
    }

    return (
        <Page>
            <div className="LoginWrapper">
                <div className="LoginFormWrapper">
                    <Form>
                        <FormRow label="Account Creation Code">
                            <Field 
                                prepend={<Icon icon="HashOutline" fill="white" height="24" width="24" paddingSize="l" />}
                                type="text"
                                placeholder="Code"
                                id="AccountCreationCodeInput"
                            />
                        </FormRow>
                        <FormRow label="Username">
                            <Field 
                                prepend={<Icon icon="PersonOutline" fill="white" height="24" width="24" paddingSize="l" />}
                                type="text"
                                placeholder="Username"
                                id="AccountCreationUsernameInput"
                            />
                        </FormRow>
                        <FormRow label="Password">
                            <Field 
                                prepend={<Icon icon="LockOutline" fill="white" height="24" width="24" paddingSize="l" />}
                                type="password"
                                placeholder="Password"
                                id="AccountCreationPasswordInput"
                                onKeyPress={(ev) => {
                                    if(ev.key === "Enter") {
                                      ev.preventDefault();
                                      createAccount();
                                    }
                                }}
                            />
                        </FormRow>
                        <Spacer />
                        <FormRow button>
                            <Button 
                                color="primary" 
                                filled 
                                onClick={createAccount}
                                loading={loading}
                            >Create Account</Button>
                        </FormRow>
                    </Form>
                </div>        
            </div>
        </Page>
    );
}

export default CreateAccount;