import axios from 'axios';

const serverURL = "https://xthou.com";

class Account {
    static async createAccount() {
        const accountCreationCodeInput = document.getElementById("AccountCreationCodeInput").value;
        const usernameInput = document.getElementById("AccountCreationUsernameInput").value;
        const passwordInput = document.getElementById("AccountCreationPasswordInput").value;

        if(accountCreationCodeInput && usernameInput && passwordInput) {
            const url = serverURL + "/api/account/create";
            try {
                await axios.post(url, {
                    creationCode: accountCreationCodeInput,
                    username: usernameInput,
                    password: passwordInput
                });
                alert("Account Created Successfully!");
                document.getElementById("AccountCreationCodeInput").value = "";
                document.getElementById("AccountCreationUsernameInput").value = "";
                document.getElementById("AccountCreationPasswordInput").value = "";
            }catch (e) {
                alert("Account Creation Failed!");
            }

        }else {
            alert("Invalid Input");
        }
    }

    static async changePassword(token) {
        const currentPassword = document.getElementById("PasswordChangeCurrentPasswordInput").value;
        const newPassword = document.getElementById("PasswordChangeNewPasswordInput").value;

        if(currentPassword && newPassword) {
            const url = serverURL + "/api/account/change";
            const authorizationToken = "Bearer " + token;
            try {
                const payload = {
                    toChange: "password",
                    currentPassword: currentPassword,
                    newPassword: newPassword
                };
                await axios({
                    url: url,
                    method: "POST",
                    headers: {'Content-Type': 'application/json', 'authorization': authorizationToken},
                    data: payload
                    
                });
                window.localStorage.removeItem('token');
                window.sessionStorage.removeItem('files');
                alert("Password changed successfully!");
                document.getElementById("PasswordChangeCurrentPasswordInput").value = "";
                document.getElementById("PasswordChangeNewPasswordInput").value = "";

            }catch (e) {
                alert("Password change failed!");
            }
        }
    }
}

export default Account;