import { useState } from 'react';
import {
    Page,
    Form,
    FormRow,
    Field,
    Icon,
    Spacer,
    Button,
} from './xui';

import Backend from '../Backend';

function ChangePassword() {
    const [loading, setLoading] = useState(false);

    async function changePassword() {
        if(window.localStorage.getItem('token')) {
            const tokenValid = await Backend.validateToken(window.localStorage.getItem('token'));
            if(tokenValid) {
                setLoading(true);
                await Backend.changePassword(window.localStorage.getItem('token'));
                setLoading(false);
            }
        }
    }
    
    return (
        <Page>
            <div className="LoginWrapper">
                <div className="LoginFormWrapper">
                    <Form>
                        <FormRow label="Current Password">
                            <Field 
                                prepend={<Icon icon="LockOutline" fill="white" height="24" width="24" paddingSize="l" />}
                                type="password"
                                placeholder="Current Password"
                                id="PasswordChangeCurrentPasswordInput"
                            />
                        </FormRow>
                        <FormRow label="New Password">
                            <Field 
                                prepend={<Icon icon="LockOutline" fill="white" height="24" width="24" paddingSize="l" />}
                                type="password"
                                placeholder="New Password"
                                id="PasswordChangeNewPasswordInput"
                                onKeyPress={(ev) => {
                                    if(ev.key === "Enter") {
                                      ev.preventDefault();
                                      changePassword();
                                    }
                                }}
                            />
                        </FormRow>
                        <Spacer />
                        <FormRow button>
                            <Button 
                                color="primary" 
                                filled 
                                onClick={changePassword}
                                loading={loading}
                            >Change Password</Button>
                        </FormRow>
                    </Form>
                </div>        
            </div>
        </Page>
    );
}

export default ChangePassword;