import './File.css';
import {
    ContentGroup,
    ContentGroupItem,
    Panel,
    Text,
    Icon,
    HorizontalRule,
    Spacer,
    IconButton
} from './xui';

import Backend from '../Backend';

const serverURL = "https://xthou.com";

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert("Copied to clipboard!");
}

function File(props) {
    const uniqueID = props.file.uniqueID, name = props.file.fileName, size = props.file.fileSize, timeOfUpload = props.file.timeOfUpload, fileType = props.file.fileType;
    const validImageTypes = ["jpg", "jpeg", "png", "gif", "image/jpg", "image/jpeg", "image/png", "image/gif"];
    const validVideoTypes = ["mp4", "mov", "webm", "video/mp4", "video/quicktime", "video/webm"];

    async function deleteFile(fileUUID) {
        const deleteSuccessful = await Backend.deleteFile(window.localStorage.getItem('token'), fileUUID);
        if(deleteSuccessful) {
            alert("File deleted!");
        }else {
            alert("File delete unsuccessful!");
        }
    }

    let isImage = validImageTypes.includes(fileType), isVideo = validVideoTypes.includes(fileType);
    let fileTop;
    
    if(isImage) {
        fileTop = (
            <img className="FileImage" src={serverURL + "/f/v/" + uniqueID} />
        );
     
    }else if(isVideo) {
        fileTop = (
            <video className="FileVideo" controls src={serverURL + "/f/v/" + uniqueID} />
        );
    
    }else if(!isImage && !isVideo) {
        fileTop = (
            <div className="DefaultFileTop">
                <Icon icon="FileOutline" fill="white" height="120" width="120" paddingSize="l" />
            </div>
        );
    }

    return (
        <>
            <ContentGroupItem grow={props.grow} alignItems="center">
                <Panel hasShadow>
                    <div className="FileTop">
                        {fileTop}
                    </div>
                    <HorizontalRule />
                    <Spacer />
                    <div className="FileBottom">
                        <Panel hasBorder>
                            <ContentGroup>
                                <ContentGroupItem grow={false} justifyContent="center" alignItems="center">
                                    <Icon icon="BookmarkOutline" fill="white" height="32" width="32" paddingSize="l" />
                                </ContentGroupItem>
                                <ContentGroupItem alignItems="center" justifyContent="center">
                                    <Text size="s">{name}</Text>
                                </ContentGroupItem>
                            </ContentGroup>
                        </Panel>
                        <Spacer />
                        <Panel hasBorder>
                            <ContentGroup>
                                <ContentGroupItem grow={false} justifyContent="center" alignItems="center">
                                    <Icon icon="ArchiveOutline" fill="white" height="32" width="32" paddingSize="l" />
                                </ContentGroupItem>
                                <ContentGroupItem alignItems="center" justifyContent="center">
                                    <Text size="s">{formatBytes(size)}</Text>
                                </ContentGroupItem>
                            </ContentGroup>
                        </Panel>
                        <Spacer />
                        <Panel hasBorder>
                            <ContentGroup>
                                <ContentGroupItem grow={false} justifyContent="center" alignItems="center">
                                    <Icon icon="CalendarOutline" fill="white" height="32" width="32" paddingSize="l" />
                                </ContentGroupItem>
                                <ContentGroupItem alignItems="center" justifyContent="center">
                                    <Text size="s">{new Date(timeOfUpload).toLocaleString('en-US')}</Text>
                                </ContentGroupItem>
                            </ContentGroup>
                        </Panel>
                        <Spacer />
                        <HorizontalRule />
                        <Spacer />
                        <ContentGroup responsive={false}>
                            <ContentGroupItem alignItems="center" justifyContent="center">
                                <IconButton icon="CloudDownloadOutline" color="primary" filled fill="white" size="l" onClick={() => window.open(serverURL + "/f/d/" + uniqueID)} />
                            </ContentGroupItem>
                            <ContentGroupItem alignItems="center" justifyContent="center">
                                <IconButton icon="EyeOutline" color="primary" filled fill="white" size="l" onClick={() => window.open(serverURL + "/f/v/" + uniqueID)} />
                            </ContentGroupItem>
                            <ContentGroupItem alignItems="center" justifyContent="center">
                                <IconButton icon="CodeDownloadOutline" color="primary" filled fill="white" size="l" onClick={() => copyToClipboard(serverURL + "/f/d/" + uniqueID)} />
                            </ContentGroupItem>
                            <ContentGroupItem alignItems="center" justifyContent="center">
                                <IconButton icon="Link2Outline" color="primary" filled fill="white" size="l" onClick={() => copyToClipboard(serverURL + "/f/v/" + uniqueID)} />
                            </ContentGroupItem>
                            <ContentGroupItem alignItems="center" justifyContent="center">
                                <IconButton icon="CloseOutline" color="danger" filled fill="white" size="l" onClick={() => deleteFile(uniqueID)} />
                            </ContentGroupItem>
                        </ContentGroup>
                        <Spacer />
                    </div>
                </Panel>
            </ContentGroupItem>
        </>
    );
}

export default File;